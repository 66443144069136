module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'field__subtext\'>\n    <div class=\'subtext__url\'>\n        <span class=\'url__text\'>.myshopify.com</span>\n        <span><button class=\'url-edit ' +
((__t = ( button? "url-edit__button" : "url-edit__link" )) == null ? '' : __t) +
'\'>' +
((__t = ( editText )) == null ? '' : __t) +
'</button></span>\n    </div>\n    <div class=\'subtext__help\'>\n        ' +
((__t = ( helpText )) == null ? '' : __t) +
'\n        <button class="link__learn">' +
((__t = ( learnMore )) == null ? '' : __t) +
'</button>\n    </div>\n</div>\n';

}
return __p
}