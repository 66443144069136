module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'signup-modal__content\'>\n    <h2 class=\'modal__heading\' id=\'ModalTitle\'>' +
((__t = ( signupHeader )) == null ? '' : __t) +
'</h2>\n</div>\n';

}
return __p
}